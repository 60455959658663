<template>
  <div >
    <b-row>
      <b-col md="6">
        <b-card
            no-body

            class="card-statistics border-success" >
          <b-card-header >
            <b-card-title >
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                <div> {{GetStageName(awards.stage)}}</div>
<!--                <div v-if="awards.stage === 1">-->
<!--                  岗位任职条件审核-->
<!--                </div>-->
<!--                <div v-if="awards.stage === 2">-->
<!--                  初评阶段投票阶段-->
<!--                </div>-->
<!--                <div v-if="awards.stage === 3">-->
<!--                  终评阶段投票阶段-->
<!--                </div>-->
<!--                <div v-if="awards.stage === 4">-->
<!--                  确定拟聘推荐人选-->
<!--                </div>-->
              </b-badge>
            </b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-button
                  variant="outline-success"
                  pill

              >
                请投票
              </b-button>
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col >
                <b-media no-body>
                  <b-media-aside
                      class="mr-2"
                  >
                    <b-avatar
                        size="48"
                        variant="success"
                    >
                      <feather-icon
                          size="24"
                          icon="UsersIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{items.length}}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      人数
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col>
                <b-media no-body>
                  <b-media-aside
                      class="mr-2"
                  >
                    <b-avatar
                        size="48"
                        variant="danger"
                    >
                      <feather-icon
                          size="24"
                          icon="ThumbsUpIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      <div v-if="awards.vote_config === -1">无限制</div>
                      <div v-if="awards.vote_config !== -1">
                        {{ awards.vote_config  - agreeVote }}
                      </div>

                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      同意票剩余
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>


          </b-card-body>
        </b-card>
      </b-col>


      <b-col md="6">
        <b-card
            no-body
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                  icon="LockIcon"
                  size="18"
              />
              <span class="align-middle ml-50">信息数据</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <span>主题</span>
                {{ awards.title }}
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <span>当前轮次</span>
                <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                >
                  {{ awards.round }}
                </b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <span>当前限制总票数</span>
                <b-badge
                    variant="primary"
                    pill
                    class="badge-round"
                >
                  <div v-if="awards.vote_config === -1">
                    无限制
                  </div>
                  <div v-if="awards.vote_config > -1">
                    {{ awards.vote_config }}
                  </div>


                </b-badge>
              </b-list-group-item>

            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>


    </b-row>

<b-row v-if="awards.job_where_one_show === 1 || awards.job_where_two_show === 1">
  <b-col md="12">
    <b-card
        no-body
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
              icon="CheckCircleIcon"
              size="18"
          />
          <span class="align-middle ml-50">任职条件</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-alert
            variant="primary"
            show
            v-if="awards.job_where_one_show === 1"
        >
          <div class="alert-body">
            <span><strong>条件1:</strong> <div v-html=awards.job_where_one></div></span>
          </div>
        </b-alert>
        <b-alert
            variant="primary"
            show
            v-if="awards.job_where_two_show === 1"
        >
          <div class="alert-body">
            <span><strong>条件2:</strong><div v-html=awards.job_where_two></div></span>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
  </b-col>


</b-row>

    <b-card
        no-body
        class="mb-0"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
              icon="LockIcon"
              size="18"
          />
          <span class="align-middle ml-50">投票列表</span>
        </b-card-title>
      </b-card-header>
      <b-row style="padding-bottom: 20px" v-if="awards.vote_config === -1">
        <b-col md="5"></b-col>
        <b-col md="4"></b-col>
        <b-col>
          <b-button
              :variant="color"
              @click="allSelect"
              :disabled="awards.appraise_status === 0 "
              v-if="awards.stage === 1"
          >
            一键符合
          </b-button>
          <b-button
              :variant="color"
              @click="allSelect"
              :disabled="awards.appraise_status === 0 "
              v-if="awards.stage !== 1"
          >
            一键同意
          </b-button>
        </b-col>
      </b-row>



      <b-table
          striped
          hover

          small
          sticky-header
          :items="items"
          :fields="fields"

      >
        <template #cell(index)="row">
          {{row.index +1}}
        </template>
        <template #cell(duty_name)="row">
          {{row.item.duty_name+row.item.duty_lv_name}}

        </template>
        <template #cell(vote)="row">
          <b-form-radio-group
              :options="radioLists1"
              :button-variant="color"
              buttons
              v-model="row.item.vote"
              @input="selectRadio"
              @change="selectCurrRadio(row.index,row.item,0)"
              required
              :disabled="awards.appraise_status === 0"
          >
          </b-form-radio-group>
        </template>
        <template #cell(vote1)="row">
          <b-form-radio-group
              :options="radioLists"
              :button-variant="color"
              buttons
              v-model="row.item.vote1"
              @input="selectRadio"
              @change="selectCurrRadio(row.index,row.item,1)"
              required
              :disabled="awards.appraise_status === 0"
          >
          </b-form-radio-group>
        </template>
        <template #cell(vote2)="row">
          <b-form-radio-group
              :options="radioLists"
              :button-variant="color"
              buttons
              v-model="row.item.vote2"
              @input="selectRadio"
              @change="selectCurrRadio(row.index,row.item,2)"
              required
              :disabled="awards.appraise_status === 0"
          >
          </b-form-radio-group>
        </template>
      </b-table>

      <b-row>
        <b-col
            offset-md="5"
        >
          <b-form-group

              label-for="search"
          >
            <b-button
                variant="primary"
                class="mr-2"
                type="button"
                @click="saveAppraise"
                v-if="showSave"
            >确认提交</b-button>



          </b-form-group>
        </b-col>
      </b-row>

    </b-card>


  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BTable, VBToggle, BButton, BBadge, BModal, BCardBody, BImg, VBTooltip, BAlert, BDropdown, BDropdownItem,
  BFormGroup,BFormRadioGroup,BCardHeader,BCardTitle,BMediaBody,BMedia,BMediaAside,BAvatar,BCardText,BSidebar,BFormInput, BListGroup, BListGroupItem,BFormRadio
} from 'bootstrap-vue'
import 'animate.css'
import vSelect from 'vue-select'
import ImgLists from "@/views/awards/ImgLists.vue";

export default {
  name: "appraise",
  components: {
    BAvatar,
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BModal,
    BCardBody,
    BImg,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BCardText,
    ImgLists,
    BFormInput,
    BSidebar,
    BCardHeader,BCardTitle,
    BMediaBody,BMedia,BMediaAside,
    BListGroup, BListGroupItem,BFormRadio,BFormRadioGroup,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      awards_id:Number(this.$route.query.id),
      pdf_url:"",
      confirm:0,
      request_id:0,
      fields:[],
      fields1:[
        {key: 'index', label: '序号',},
        {key: 'name', label: '姓名',},
        {key: 'department_name', label: '部门' },
        {key: 'duty_name', label: '竞聘岗位',},
        {key: 'vote1', label: '任职条件1',},
        {key: 'vote2', label: '任职条件2',},
      ],
      fields2:[
        {key: 'index', label: '序号',},
        {key: 'name', label: '姓名',},
        {key: 'department_name', label: '部门' },
        {key: 'duty_name', label: '竞聘岗位',},
        {key: 'vote1', label: '任职条件1',},
      ],
      fields3:[
        {key: 'index', label: '序号',},
        {key: 'name', label: '姓名',},
        {key: 'department_name', label: '部门' },
        {key: 'duty_name', label: '竞聘岗位',},
        {key: 'vote', label: '投票' },
      ],
      radioLists:[{text:"符合",value:1},{text:"不符合",value:0}],
      radioLists1:[{text:"同意",value:1},{text:"不同意",value:0}],
      items: [],
      currRadio:0,
      timer:null,
      awards: {
        appraise_status:0,
        affirm_status:0,
        round:0,
        vote_config:0,
        vote_number:0,
        job_where_one:"",
        job_where_two:"",
        job_where_one_show:0,
        job_where_two_show:0
      },
      surplusCount:-1,// 已经计算出的同意票总数
      voteLists1:[],
      voteLists2:[],
      showSave:true,
      showViewData:{},
      selectNumber:[],
      agreeVote:0,
      color:"",
      reloadMsg:""
    }
  },

  created() {
    console.log(this.$route.query.id)
    this.getAwards()
    this.setTimes()


  },

  destroyed() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer)
    this.timer = null
    console.log('清除定时器')
  },

  watch:{
    'awards.round':{
      handler(newVal,oldVal){
        if (newVal !== oldVal){
            this.getAwardsAppraiseLists()
            this.voteLists = []
            this.showSave = true
          this.agreeVote = 0
        }
      }
    },
    'awards.stage':{
      handler(newVal,oldVal){
        if (newVal !== oldVal){
          this.getAwardsAppraiseLists()
          this.voteLists = []
          this.showSave = true
          this.agreeVote = 0
        }
      }
    },


  },

  methods:{


    allSelect(){
      if (this.awards.stage === 1){
        for (let i = 0; i < this.items.length; i++) {
          var r = this.items[i]
          if (this.awards.vote_number === 0){
            r.vote1 = 1
          }else{
            r.vote1 = 1
            r.vote2 = 1
          }

          this.$set(this.items, i, r)
        }
      }else{
        for (let i = 0; i < this.items.length; i++) {
          var r = this.items[i]
          r.vote = 1
          this.$set(this.items, i, r)
        }
      }

    },

    selectRadio(e){
      this.currRadio = e
    },

    ifSelectNull(){
      for (let i = 0; i < this.items.length; i++) {
        var r = this.items[i]
        if (this.awards.stage === 1){
          if (this.awards.vote_number === 0) {
            if (r.vote1 === -1 ) {
              var index = i + 1
              this.showToast(400, "您还没有对序号: " + index + " 的" + this.items[i].name + " 任职条件全部发表意见,因此无法提交")
              return false
            }
          }else {
            if (r.vote1 === -1 || r.vote2 === -1) {
              var index = i + 1
              this.showToast(400, "您还没有对序号: " + index + " 的" + this.items[i].name + " 任职条件全部发表意见,因此无法提交")
              return false
            }
          }
        }else {
          if (r.vote === -1) {
            var index = i + 1
            this.showToast(400, "您还没有对序号: " + index + " 的" + this.items[i].name + " 发表意见,因此无法提交")
            return false
          }
        }

      }
      return true
    },

    selectCurrRadio(index,row,where){
      var agreeCount = 0
      for (let i = 0; i < this.items.length; i++) {
        var r = this.items[i]
        if (r.vote === 1 || r.vote1 === 1 || r.vote2 === 1){
          ++agreeCount
        }
      }
      if (this.currRadio === 1 && this.awards.vote_config !== -1){
        if (agreeCount > this.awards.vote_config){
          if (where === 0){
            this.items[index].vote = -1
          }else if(where === 1){
            this.items[index].vote1 = -1
          }else{
            this.items[index].vote2 = -1
          }
          this.showToast(400,"本轮投票同意票最多可投: "+this.awards.vote_config+" 票,已超过可用票数")
          return false
        }
      }
      this.agreeVote = agreeCount


    },



    getAwardsAppraiseLists(){
      this.AppraiseLists({awards_id:this.awards_id,round:this.awards.round,}).then(res =>{
        this.items = res.data.data.lists
        // this.selectNumber.push({"name":"无限制","value":-1})
        // for (let i = 1; i < this.items.length; i++) {
        //   this.selectNumber.push({"name":i,"value":i})
        // }


      })
    },

    getAwards(){
      this.AwardsGet({id:this.awards_id}).then(res =>{
        this.awards = res.data.data
        this.surplusCount = this.awards.vote_config
        if (this.awards.stage === 1) {
          if (this.awards.vote_number === 0){
            this.fields = this.fields2
          }else{
            this.fields = this.fields1
          }

        } else {
          this.fields = this.fields3
        }
        if (this.awards.appraise_status === 0){
           this.color="outline-secondary"
          this.pushMsg(400,'管理员还没有下发开启投票指令,请稍等')

        }else{
          this.color="outline-success"
          var  msg = "当前进入 "+this.GetStageName(this.awards.stage)+" 中第 "+this.awards.round+" 轮投票"
          this.pushMsg(200,'管理员开启投票,'+msg)

        }

      })
    },

    pushMsg(code,msg){
      if (this.reloadMsg === msg){
        return false
      }
      this.showToast(code,msg)
      this.reloadMsg = msg
    },




    setTimes() {
      if (this.timer === null) {
        this.timer = setInterval(() => {

          this.getAwards()
        }, 10000)
      }
    },







    saveAppraise(){
      var isNull = this.ifSelectNull()
      if (!isNull){
        return false
      }
      console.log(234)
      this.awardsAppraise({"awards_id":this.awards.id,"round":this.awards.round,"data":this.items,"stage":this.awards.stage}).then(res =>{
        this.showToast(res.data.code,res.data.msg)
        if (res.data.code === 200){
          this.showSave = false
          this.$swal({
            title: '操作成功',
            icon: 'success',
            text: res.data.msg +"(此消息将会在5秒内自动关闭)",
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            showClass: {
              popup: 'animate__animated animate__flipInX',
            },
            buttonsStyling: false,
          })
        }
      })
    },

    show_request(url,id){

      this.img_url =  url
      this.request_id = id
      this.showStudentRequest = true
    },



  },
}
</script>

